; (function ($, document, window, undefined) {
    var pluginName = 'Resizer',
         defaults = {};
    
    function Resizer(element, options) {
        this.element = $(element);
        this.options = $.extend({}, defaults, options);
        this.init();
    }

    Resizer.prototype.init = function() {
        console.log('resizer init');

        this.addUIInteractions();
        this.scale();
    };

    Resizer.prototype.addUIInteractions = function() {
        var self = this;
        $(window).resize(self.scale.bind(this));
    };

    Resizer.prototype.scale = function() {
        var thumbs = this.element.find('.images-link'),
            vh = $(window).outerHeight() - 123,
            elw = this.element.innerWidth(),
            elh = this.element.innerHeight();

            size = vh > elw ? elw : vh;

        console.log('vh:', vh, 'vw:', elw, 'size:', size);

        $.each(thumbs, function(index, image) {
            if(elw > vh) {
                $(image).css({
                    'height': (size/3) + 'px',
                    'width': (size/3) + 'px'
                });
            } 
        });
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Resizer(this, options));
            }
        });
    };
})(jQuery, document, window, undefined);